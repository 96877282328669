/* eslint-disable @typescript-eslint/no-explicit-any */
// KEEP IN SYNC WITH client_side_url_helper.rb
//
// usage:
//
// Routes.campaignNewsPath('foo')             // => '/foo/neuigkeiten'
// Routes.campaignNewsPath('foo', { x: 'y' }) // => '/foo/neuigkeiten?x=y'
// Routes.campaignNewsURL('foo')              // => 'https://www.goodcrowd.dev/foo/neuigkeiten'

import { QueryHelper } from 'shared'

export const Routes: Record<string, (...args: any[]) => string> = {}

Routes.root = () => '/'
Routes.notFound = () => '/not_found'

Routes.campaigns = () => '/campaigns'
Routes.campaign = (slug: any) => `/${slug}`
Routes.campaignPoster = (slug: any) => `/${slug}/poster`
Routes.campaignNews = (slug: any) => `/${slug}/neuigkeiten`
Routes.campaignNewsItem = (slug: any, id: any) => `/${slug}/neuigkeiten/${id}`
Routes.newCampaign = (slug: any) => `/${slug || 'campaign'}/new`
Routes.newCampaignMedia = (slug: any) => `/${slug || 'campaign'}/new/media`
Routes.newCampaignCrop = (slug: any) => `/${slug || 'campaign'}/new/crop`
Routes.newCampaignDescription = (slug: any) => `/${slug || 'campaign'}/new/description`
Routes.newCampaignInvoiceDetails = (slug: any) => `/${slug || 'campaign'}/new/invoice-details`

Routes.donation = (token: any) => `/donation/${token}`
Routes.newDonation = (slug: any) => `/${slug}/donate`
Routes.donationDeposit = (token: any) => `/donation/${token}/deposit`

Routes.signIn = () => '/sign_in'
Routes.signOut = () => '/sign_out'
Routes.signUp = () => '/sign_up'
Routes.newConfirmation = () => `/confirmation/new`
Routes.confirmation = (token: any) => `/confirmation/${token}`
Routes.userDeleted = () => `/goodbye`

Routes.newPassword = () => '/password/new'
Routes.editPassword = (token: any) => `/password/${token}/edit`

Routes.unsubscribeNews = () => `/unsubscribe`

// Manage
Routes.manageCampaigns = () => `/manage/campaign`
Routes.manageCampaign = (slug: any) => `/manage/campaign/${slug}`
Routes.manageCampaignMedia = (slug: any) => `/manage/campaign/${slug}/media`
Routes.manageCampaignCrop = (slug: any) => `/manage/campaign/${slug}/crop`
Routes.manageCampaignDescription = (slug: any) => `/manage/campaign/${slug}/description`

/**
 * @deprecated
 */
Routes.manageCampaignGoal = (slug: any) => `/manage/campaign/${slug}/goal`
Routes.manageCampaignInvoiceDetails = (slug: any) => `/manage/campaign/${slug}/invoice-details`
Routes.manageCampaignInvoices = (slug: any) => `/manage/campaign/${slug}/invoices`
Routes.manageCampaignNewNews = (slug: any) => `/manage/campaign/${slug}/news/new`
Routes.manageCampaignEditNews = (slug: any, id: any) => `/manage/campaign/${slug}/news/${id}/edit`
Routes.manageCampaignNews = (slug: any) => `/manage/campaign/${slug}/news`
Routes.manageCampaignSlug = (slug: any) => `/manage/campaign/${slug}/slug`
Routes.manageCampaignShares = (slug: any) => `/manage/campaign/${slug}/share`
Routes.manageCampaignShare = (slug: any, id: any) => `/manage/campaign/${slug}/share/${id}`
Routes.manageCampaignDonations = (slug: any) => `/manage/campaign/${slug}/donations`
Routes.manageCampaignDonorReports = (slug: any) => `/manage/campaign/${slug}/donor-reports`
Routes.manageCampaignClose = (slug: any) => `/manage/campaign/${slug}/close`

Routes.manageCampaignPaymentMethod = (slug: any) => `/manage/campaign/${slug}/payment-method`
Routes.manageCampaignPaymentMethodStripe = (slug: any) => `/manage/campaign/${slug}/payment-method/stripe`

Routes.manageProfile = () => `/manage/profile`
Routes.manageProfileCrop = () => `/manage/profile/crop`

Routes.campaignOrRegistration = ({ state, slug }: any) => {
  return state === 'created' ? Routes.newCampaign(slug) : Routes.campaign(slug)
}

// Static
Routes.privacy = () => '/datenschutz'
Routes.usage = () => '/funktionsweise'
Routes.pricing = () => '/gebuehren'
Routes.help = () => '/hilfe'
Routes.imprint = () => '/impressum'
Routes.termsGeneral = () => '/nutzungsbedingungen-allgemein'
Routes.termsAdditional = () => '/nutzungsbedingungen-zusaetzlich'
Routes.termsRevocation = () =>
  '/nutzungsbedingungen-zusaetzlich#9-widerrufsbelehrung-gegen%C3%BCber-kampagnenmacher-in-bezug-auf-vertrag-mit-der-plattform'
Routes.aboutUs = () => '/ueber-uns'
Routes.donateFor = (slug: any) => `/spenden-fuer/${slug}`

// define ...Path and ...URL functions for each route
Object.getOwnPropertyNames(Routes).forEach((routeName) => {
  Routes[`${routeName}Path`] = (...args: any[]) => {
    const func = Routes[routeName]
    const query = args.length > func.length && args.pop()
    const path = func(...args)
    return query ? `${path}?${QueryHelper.toQuery(query)}` : path
  }

  Routes[`${routeName}URL`] = (...args: any[]) => {
    const path = Routes[`${routeName}Path`](...args)
    return absoluteURL({ path })
  }
})

export const absoluteURL = ({ path }: any) => `${window.location.origin}/${path.replace(/^\//, '')}`
